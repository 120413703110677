@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  background-color: rgb(235, 249, 249);
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

h6 {
  color: rgb(249, 164, 5);
  text-transform: uppercase;
}

h2 {
  font-weight: 900;
}

img {
  pointer-events: none;
}

section {
  padding-top: 70px;
  padding-bottom: 120px;
}

.card-effect {
  box-shadow: 0 8px 22px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 0;
  transition: all 0.3s ease;
}
.card-effect:hover {
  box-shadow: none;
  transform: translateY(4px);
}

.button a {
  width: 14rem;
  margin: auto;
  color: #fff;
  border: #fff 1px solid;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 3rem;
}

.link .row {
  margin-left: 0;
  margin-right: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(../assets/images/topVideoThumbnail.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;
}
.loading .spinner {
  margin: 0 auto;
}
.loading p {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 2.5rem;
  color: #fff !important;
}

@media (max-width: 1600px) {
  .loading {
    background-size: cover;
  }
}
.scrolldown4 {
  /*=== 9-1-4 矢印が動いてスクロールを促す  ====*/
  /*スクロールダウン全体の場所*/
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  bottom: 2%;
  right: 50%;
  /*矢印の動き1.5秒かけて永遠にループ*/
  animation: arrowmove 1.5s ease-in-out infinite;
  /* 矢印の描写 */
  /*Scrollテキストの描写*/
  /*下からの距離が変化して全体が下→上→下に動く*/
}
.scrolldown4:before {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: 0;
  right: -3px;
  /*矢印の形状*/
  width: 1px;
  height: 20px;
  background: #eee;
  transform: skewX(-15deg);
}
.scrolldown4:after {
  content: "";
  /*描画位置*/
  position: absolute;
  bottom: 0;
  right: 0;
  /*矢印の形状*/
  width: 1px;
  height: 50px;
  background: #eee;
}
.scrolldown4 span {
  /*描画位置*/
  position: absolute;
  left: -20px;
  bottom: 10px;
  /*テキストの形状*/
  color: #eee;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  /*縦書き設定*/
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}
@keyframes arrowmove {
  0% {
    bottom: 1%;
  }
  50% {
    bottom: 2%;
  }
  100% {
    bottom: 1%;
  }
}

/*========= ナビゲーションのためのCSS ===============*/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  z-index: 50;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.header a {
  margin-top: 0.2rem;
}
.header a .navbar-brand {
  width: 5.5rem;
  height: auto;
  color: #fff;
  z-index: 50;
}

.btn-volume {
  position: absolute;
  top: 1.1rem;
  left: 0;
  width: 100%;
  height: 4rem;
  z-index: 100;
}
.btn-volume .up-btn,
.btn-volume .mute-btn {
  font-size: 3rem;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 70px;
}
.btn-volume .up-btn {
  display: none;
}
.btn-volume .up-btn.muted {
  display: block;
}
.btn-volume .mute-btn {
  display: block;
}
.btn-volume .mute-btn.muted {
  display: none;
}

/*========= ボタンのためのCSS ===============*/
.openbtn {
  position: fixed;
  z-index: 1000; /*ボタンを最前面に*/
  top: 1.8rem;
  right: 1.5rem;
  cursor: pointer;
  width: 50px;
  height: 50px;
  /*×に変化*/
}
.openbtn span {
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  left: 14px;
  height: 1px;
  border-radius: 2px;
  background-color: #fff;
  width: 45%;
}
.openbtn span:nth-of-type(1) {
  top: 15px;
}
.openbtn span:nth-of-type(2) {
  top: 23px;
}
.openbtn span:nth-of-type(3) {
  top: 31px;
  width: 38%;
}
.openbtn.active span:nth-of-type(1) {
  top: 18px;
  left: 18px;
  transform: translateY(6px) rotate(-45deg);
  width: 30%;
  background-color: #000;
}
.openbtn.active span:nth-of-type(2) {
  opacity: 0;
}
.openbtn.active span:nth-of-type(3) {
  top: 30px;
  left: 18px;
  transform: translateY(-6px) rotate(45deg);
  width: 30%;
  background-color: #000;
}

#g-nav {
  /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
  position: fixed;
  z-index: 999;
  /*ナビのスタート位置と形状*/
  top: -120%;
  left: 0;
  width: 100%;
  height: 100vh; /*ナビの高さ*/
  background: #fff;
  /*動き*/
  transition: all 0.3s;
  /*アクティブクラスがついたら位置を0に*/
  /*ナビゲーション*/
}
#g-nav.panelactive {
  top: 0;
  /*ナビゲーションの縦スクロール*/
}
#g-nav.panelactive #g-nav-list {
  /*ナビの数が増えた場合縦スクロール*/
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh; /*表示する高さ*/
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
#g-nav ul {
  /*ナビゲーション天地中央揃え*/
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#g-nav ul li {
  text-align: center;
}
#g-nav ul li a {
  color: #000;
  text-decoration: none;
  padding: 10px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 2.5rem;
}
#g-nav ul li a:hover {
  color: #c7c5c5;
}

.toppage {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.toppage video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
}
.toppage .title {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}
.toppage .title h1 {
  font-size: 5rem;
  font-weight: 900;
  text-shadow: #000 1px 3px 6px;
}
.toppage .copyright {
  position: absolute;
  top: 50%;
  left: -7%;
  transform: translateY(-50%);
  transform: rotate(90deg);
  transform-origin: center;
}
.toppage .copyright p {
  font-size: 0.5rem;
  font-weight: 200;
}
.toppage .sns {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  z-index: 100;
}
.toppage .sns ul {
  display: block;
  flex-direction: column !important;
}
.toppage .sns ul li {
  padding-bottom: 2rem;
}
.toppage .sns ul li a {
  display: inline-block;
  transition: all 0.25s ease;
}
.toppage .sns ul li a:hover {
  transform: translateY(-5px);
}
.toppage .sns ul li a img {
  width: 2rem;
  height: auto;
}

.works {
  background: rgb(45, 42, 96);
  background: linear-gradient(90deg, #38485e 0%, rgb(76, 91, 90) 29%, rgb(67, 83, 81) 71%, #38485e 100%);
}
.works h4 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  font-weight: 700;
}
.works .works__wrapper {
  display: flex;
  gap: 1rem;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: flex-start;
}
.works .works__wrapper:after {
  content: "";
  width: 32%;
  height: 0;
  display: block;
}
.works .works__wrapper .works__item {
  height: 31rem;
  overflow: hidden;
  width: 32%;
}
.works .works__wrapper .works__item .works__img__a {
  display: inline-block;
  height: auto;
  width: 100%;
}
.works .works__wrapper .works__item a img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  object-position: center;
  opacity: 1;
  transition: 0.3s ease-in-out; /*移り変わる速さを変更したい場合はこの数値を変更*/
}
.works .works__wrapper .works__item a:hover img {
  opacity: 0.7;
  cursor: pointer;
}
.works .works__wrapper .works__item h5 {
  color: #000;
  font-size: 1.5rem;
  padding: 0 0.5rem;
  margin-top: 1.9rem;
  font-weight: bolder;
}
.works .works__wrapper .works__item p {
  color: #000;
  font-size: 1rem;
}
.works .home {
  padding: 0;
}
.works .design {
  padding-top: 5rem;
  padding-bottom: 0;
}
.works .design .works__wrapper .works__item h5 {
  font-size: 1.5rem;
  padding-bottom: 2.1rem;
  padding-top: 0.4rem;
}
.works .video .works__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 1rem;
}
.works .video .works__wrapper .video-bg {
  background-color: #fff;
  width: 32%;
}
.works .video .works__wrapper .video-bg:after {
  content: "";
  width: 32%;
  height: 0;
}
.works .video .works__wrapper .card-effect {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
}
.works .video .works__wrapper .card-effect:hover {
  box-shadow: none;
  transform: translateY(0px);
}
.works .video .works__wrapper .card-effect h5 {
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 3rem;
  padding-top: 1.3rem;
}
.works .video .works__wrapper .card-effect a {
  margin-bottom: 1.7rem;
}

.about {
  background-image: url(../assets/images/about__link04.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 370vh;
  position: relative;
  z-index: 0;
  /* ============== MEDIA QUERIES (MEDIUM DEVICES) タブレットサイズ ================= */
  /* ============== MEDIA QUERIES (SMALL DEVICES) 携帯サイズ ================= */
}
.about .layer::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.about h2 {
  letter-spacing: 0.15rem;
}
.about .member {
  padding: 30px;
  height: 48rem;
  width: 100%;
  background-color: rgb(230, 227, 227);
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
}
.about .member .member-card {
  padding-right: 6rem;
  padding-left: 4rem;
  border-right: #7a7a7a 1px solid;
  margin-top: 4rem;
}
.about .member .member-card .hiroshi__img__a {
  display: inline-block;
  border-radius: 100px;
}
.about .member .member-card a:hover img {
  opacity: 0.5;
  cursor: pointer;
}
.about .member .member-card a img {
  width: 20rem;
  height: 20rem;
  border-radius: 100px;
  object-fit: cover;
  object-position: center top;
  opacity: 1;
  transition: all 0.25s ease;
}
.about .member .member-card h5,
.about .member .member-card p {
  color: #000;
}
.about .member .about-intro {
  font-size: 1.6rem;
  margin-top: 0;
  line-height: 2.6rem;
  color: #000;
  padding: 8rem;
}
.about .member .about-intro span {
  display: inline-block;
  font-weight: bold;
  font-size: 1.8rem;
  color: #000;
  padding-top: 1.1rem;
  padding-bottom: 1.3rem;
}
.about .member:hover {
  background-color: #383838;
  opacity: 0.96;
}
.about .member:hover .member-card .btn {
  color: #fff;
  border-color: #fff;
}
.about .member:hover .member-card .btn:hover {
  border-color: #383838;
}
.about .member:hover h5,
.about .member:hover p {
  color: #fff;
}
.about .member:hover .about-intro span {
  color: rgb(234, 233, 233);
}
.about .aboutLink {
  padding-top: 6rem;
  padding-bottom: 7rem;
}
.about .aboutLink h2 {
  display: inline-block;
  margin-top: 0.3rem;
}
.about .aboutLink h2 span {
  padding: 0 0.6rem;
  font-weight: lighter;
  font-size: 1.45rem;
}
.about .topBackLink {
  margin-bottom: 8rem;
}
.about .topBackLink .topBackLink-border {
  width: 95%;
  margin: 0 auto;
  margin-top: 5rem;
  border-top: #fff 1px solid;
  padding-top: 6rem;
}
.about .review iframe {
  width: 300px;
  height: 435px;
}
.about .review span {
  font-weight: lighter;
  font-size: 1.5rem;
  padding: 0 0.8rem;
}
.about .review img {
  width: 100%;
  height: 26rem;
  max-height: 26rem;
  border-radius: 2px;
  object-fit: cover;
  object-position: top left;
}
.about .review .review__flex {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.about .review .review__flex a:hover img {
  opacity: 0.6;
}
.about .review .btn {
  display: flex;
  justify-content: center;
  width: 14rem;
  margin: auto;
  color: #fff;
  border-color: #fff;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 5rem;
}
.about .review .btn:hover {
  border-color: #212529;
}
.about .contact h3 {
  font-weight: bold;
  margin-bottom: 2rem;
}
.about .contact p {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.about .contact .contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
  margin: 0 auto;
  /* =================FORM=================== */
}
.about .contact .contact__container .contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.about .contact .contact__container .contact__options .contact__option {
  background: #151545;
  padding: 2.6rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.about .contact .contact__container .contact__options .contact__option:hover {
  background: transparent;
  border-color: #151545;
}
.about .contact .contact__container .contact__options .contact__option .contact__option-icon {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}
.about .contact .contact__container .contact__options .contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}
.about .contact .contact__container form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.about .contact .contact__container form input,
.about .contact .contact__container form textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid rgba(77, 181, 255, 0.4);
  resize: none;
  color: #fff;
}
@media (max-width: 992px) {
  .about .contact .contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
@media (max-width: 600px) {
  .about .contact .contact__container {
    width: 90%;
  }
}

.link .link__wrapper {
  margin-top: 10rem;
  border-top: #fff 1px solid;
  padding-top: 7rem;
}
.link .link__wrapper .link__item {
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 0.5px solid #fff;
}
.link .link__wrapper .link__item h4 {
  font-size: 2rem;
  text-align: center;
  padding-bottom: 0.2rem;
  font-weight: 700;
  color: #eee;
}
.link .link__wrapper .link__item p {
  font-size: 1.3rem;
}
.link .link__wrapper .link__item img {
  transition: all 0.25s ease;
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
.link .link__wrapper .link__item a {
  color: #fff;
}
.link .link__wrapper .link__item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
}
.link .link__wrapper .link__item .overlay h2,
.link .link__wrapper .link__item .overlay h5 {
  color: #fff;
}
.link .link__wrapper .link__item:hover .overlay {
  opacity: 1;
}
.link .link__wrapper .link__item:hover img {
  transform: scale(1.1);
}
.link .link__wrapper .link__item .center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.link .link__wrapper .link__item .about-philosophy {
  background-color: rgba(7, 24, 1, 0.8);
}
.link .link__wrapper .link__item .about-flow {
  background-color: rgba(2, 7, 23, 0.8);
}
.link .link__wrapper .link__item .about-overview {
  background-color: rgba(77, 79, 31, 0.7);
}
.link .aboutLink__wrapper {
  margin-top: 2rem;
  border-top: 0px;
  padding-top: 0rem;
}

.link__position {
  margin-left: 18rem;
}
.link__position .link__position__title {
  font-size: 3rem;
  font-weight: 800;
  /*線の基点とするためrelativeを指定*/
  position: relative;
}
.link__position .link__position__title:hover::after {
  transform: scale(1, 1); /*X方向にスケール拡大*/
}
.link__position .link__position__title::after {
  content: "";
  /*絶対配置で線の位置を決める*/
  position: absolute;
  bottom: -3px;
  left: 0%;
  /*線の形状*/
  width: 13%;
  height: 1.5px;
  /*アニメーションの指定*/
  transition: all 0.3s;
  transform: scale(0, 1); /*X方向0、Y方向1*/
  transform-origin: center top; /*上部中央基点*/
}
.link__position .link__position__title::after {
  background: #fff;
}

.variety {
  padding: 0;
  height: 100%;
}
.variety .variety__wrapper {
  position: relative;
  width: 100vw;
  height: 510rem;
  overflow: hidden;
}
.variety .variety__wrapper video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
}
.variety .variety__wrapper::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.variety .variety__wrapper .variety__wrapper__contents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 1;
  padding-top: 100rem;
  margin-top: -80rem;
}
.variety .variety__wrapper .variety__wrapper__contents h3 {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.variety .variety__wrapper .variety__wrapper__contents .link__position {
  margin-left: 8rem;
  margin-bottom: 7rem;
}
.variety .variety__wrapper .variety__wrapper__contents .link__position p {
  font-size: 2.2rem;
  font-weight: lighter;
  padding: 0 1.5rem;
}
.variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title {
  font-size: 3rem;
  color: #fff;
  opacity: 1;
  font-weight: 800;
  /*線の基点とするためrelativeを指定*/
  position: relative;
}
.variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title:hover {
  color: #ccc;
}
.variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title:hover::after {
  transform: scale(1, 1); /*X方向にスケール拡大*/
}
.variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title::after {
  content: "";
  /*絶対配置で線の位置を決める*/
  position: absolute;
  bottom: -3px;
  left: 5%;
  /*線の形状*/
  width: 90%;
  height: 1.5px;
  /*アニメーションの指定*/
  transition: all 0.3s;
  transform: scale(0, 1); /*X方向0、Y方向1*/
  transform-origin: center top; /*上部中央基点*/
}
.variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title::after {
  background: #fff;
}
.variety .variety__wrapper .variety__wrapper__contents .fluid {
  width: 55vh; /*横幅*/
  height: 40vh; /*縦幅*/
  background-size: cover;
  background-position: center;
  animation: fluidrotate 30s ease 0s infinite; /*アニメーションの設定*/
}
@keyframes fluidrotate {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
.variety .variety__wrapper .variety__wrapper__contents .fluid-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.variety .variety__wrapper .variety__wrapper__contents .fluid-reverse .fluid-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.variety .variety__wrapper .variety__wrapper__contents .fluid-reverse .fluid-text h4,
.variety .variety__wrapper .variety__wrapper__contents .fluid-reverse .fluid-text p {
  margin-left: auto;
  margin-right: 0;
  width: 80%;
  display: block;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field {
  padding-top: 6rem;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field:hover h4 span {
  color: rgb(222, 117, 12);
  -webkit-text-stroke: 0.6px #fff;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text h4,
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text p {
  width: 80%;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text h4 span {
  font-size: 7.5rem;
  padding-right: 0.5rem;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text p {
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid01 {
  background-image: url(../assets/images/accom01-01.jpg); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid02 {
  background-image: url(../assets/images/accom01-02.jpg); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid03 {
  background-image: url(../assets/images/accom01-03.jpg); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept {
  /*　画像の拡大＋テキスト出現　*/
  /*　モノクロからカラーへ　*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept img {
  width: 100%;
  height: 62rem;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #fff;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText {
  /*テキストの基点となる位置を定義*/
  position: relative;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.mask {
  position: relative;
  transition: 0.3s ease-in-out; /*移り変わる速さを変更したい場合はこの数値を変更*/
  display: block; /*画像をくくるspanタグをブロック要素にする*/
  line-height: 0; /*行の高さを0にする*/
  overflow: hidden; /*拡大してはみ出る要素を隠す*/
  border-radius: 1rem;
  border: 1px solid #fff;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText img {
  transform: scale(1);
  filter: blur(0);
  transition: 0.3s ease-in-out; /*移り変わる速さを変更したい場合はこの数値を変更*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText:hover span.mask::before {
  /*hoverした時の変化*/
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText:hover img {
  /*hoverした時の変化*/
  transform: scale(1.1); /*拡大の値を変更したい場合はこの数値を変更*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText:hover span.cap {
  /*hoverした時の変化*/
  opacity: 1;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap {
  opacity: 0;
  transition: 0.4s ease-in-out; /*移り変わる速さを変更したい場合はこの数値を変更*/
  position: absolute;
  z-index: 3; /*テキストを前面に出す*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /*テキストの色を変えたい場合はここを修正*/
  line-height: 1.5; /*行の高さを1.5にする*/
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul li {
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul li span.concept__fontWeight {
  font-weight: 800;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__mainText li span.concept__hover:hover span.concept__hover__color {
  color: rgb(222, 117, 12);
  -webkit-text-stroke: 0.6px #fff;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__mainText:hover .concept__lewis {
  opacity: 0.1;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__lewis {
  color: rgba(245, 245, 245, 0.9176470588);
  opacity: 1;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__lewis li {
  line-height: 2.5rem;
  margin-bottom: 0rem;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__lewis .concept__lewis__name {
  text-align: right;
}
.variety .variety__wrapper .variety__wrapper__contents .concept .grayscale img {
  filter: grayscale(100%); /*モノクロ具合を変更したい場合はこの数値を変更*/
  transition: 0.3s ease-in-out; /*移り変わる速さを変更したい場合はこの数値を変更*/
}
.variety .variety__wrapper .variety__wrapper__contents .concept .grayscale .mask:hover img {
  /*hoverした時の変化*/
  filter: grayscale(0);
}
@media (max-width: 1200px) {
  .variety .variety__wrapper .variety__wrapper__contents {
    /*　モノクロからカラーへ　*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .zoomInText span.mask::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /*背景色*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .zoomInText img {
    transform: scale(1.1); /*拡大の値を変更したい場合はこの数値を変更*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .zoomInText span.cap {
    opacity: 1 !important;
  }
  .variety .variety__wrapper .variety__wrapper__contents .grayscale .mask img {
    filter: grayscale(0);
  }
}
.variety .variety__wrapper .variety__wrapper__contents .design h4 {
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 1.4rem;
}
.variety .variety__wrapper .variety__wrapper__contents .design h5 {
  font-size: 1.65rem;
  line-height: 2.4rem;
  margin-bottom: 8rem;
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field {
  padding-top: 4rem;
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field:hover p span {
  color: rgb(100, 222, 12);
  -webkit-text-stroke: 1px rgb(255, 255, 255);
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p {
  width: 80%;
  margin-bottom: 1.6rem;
  font-size: 1.7rem;
  line-height: 3.5rem;
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p span {
  font-size: 7.5rem;
  padding-right: 0.5rem;
  font-weight: 600;
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid04 {
  background-image: url(../assets/images/Allo04.jpg); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid05 {
  background-image: url(../assets/images/Allo05.jpg); /*背景色*/
}
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid06 {
  background-image: url(../assets/images/Allo08.jpg); /*背景色*/
}

.flow {
  padding: 0;
}
.flow .flow__wrapper {
  position: relative;
  width: 100vw;
  height: 360rem;
  overflow: hidden;
}
.flow .flow__wrapper video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
}
.flow .flow__wrapper::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.89);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.flow .flow__wrapper .flow__wrapper__contents {
  position: absolute;
  top: 6%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 1;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar {
  margin: 0 auto;
  width: 70%;
  margin-bottom: 10rem;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap {
  margin: 5rem 0;
  position: relative;
  transition: all 0.3s ease;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap:hover .steptitle .stepcircle {
  opacity: 0.3;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap:hover .steptitle .title {
  opacity: 0.9;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle {
  display: inline-flex;
  align-items: center;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .stepcircle {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  content: "";
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  text-align: center;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .stepcircle span {
  display: inline-block;
  line-height: 1.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
  top: 0.9em;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .title {
  margin: 0.8rem;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 3rem;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt {
  padding-left: 7rem;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt {
  font-size: 1.5rem;
  line-height: 3rem;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .txt__weight {
  font-weight: bold;
  color: #d3d2d2;
  font-size: 1.7rem;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .orange {
  color: #eda76e;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .stepline {
  width: 1px;
  height: calc(100% + 4.9rem);
  background-color: #000;
  position: absolute;
  top: 1em;
  left: 1.5em;
  z-index: -1;
}
.flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap:last-of-type .stepline:last-of-type {
  display: none;
}
@media screen and (max-width: 960px) {
  .flow .flow__wrapper .flow__wrapper__contents .stepbar {
    width: 90%;
  }
}

.overview {
  padding: 0;
}
.overview .overview__wrapper {
  position: relative;
  width: 100vw;
  height: 440rem;
  overflow: hidden;
}
.overview .overview__wrapper video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
}
.overview .overview__wrapper::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.overview .overview__wrapper .overview__text__wrapper {
  position: absolute;
  top: 5%;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 1;
}
.overview .overview__wrapper .overview__text__wrapper h2 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card {
  padding: 0;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  margin-top: 8rem;
  margin-bottom: 28rem;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card {
  border-right: #fff 1px solid;
  padding-right: 4rem;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card img {
  width: 15rem;
  height: 15rem;
  border-radius: 100px;
  object-fit: cover;
  object-position: center top;
  opacity: 1;
  transition: all 0.25s ease;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card img:hover {
  opacity: 0.7;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card .btn {
  color: #fff;
  border-color: #fff;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card .btn:hover {
  border-color: #212529;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .overview__text {
  padding-left: 7rem;
}
.overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .overview__text ul li {
  font-size: 1.7rem;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
}
.overview .overview__wrapper .overview__text__wrapper .work {
  padding-top: 15rem;
  /* アコーディオン全体 */
  /* アコーディオン展開時の(-)アイコン */
}
.overview .overview__wrapper .overview__text__wrapper .work h2 {
  letter-spacing: 0.2rem;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion {
  max-width: 800px;
  margin: 0 auto;
  /* アコーディオン */
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item {
  border: 1px solid #ccc;
  margin-top: 10px;
  cursor: pointer;
  /* アコーディオンのタイトル */
  /* アコーディオンのコンテンツ */
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__title {
  position: relative;
  font-weight: bold;
  cursor: pointer;
  font-size: 2rem;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__title span {
  font-size: 1.8rem;
  font-weight: lighter;
  padding: 0 0.7rem;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__title::before {
  /* (+)アイコン */
  content: "";
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #3abec1;
  width: 20px;
  height: 4px;
  transition: all 0.3s;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__title::after {
  /* (+)アイコン */
  content: "";
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #3abec1;
  width: 20px;
  height: 4px;
  transition: all 0.3s;
  transform: rotate(90deg);
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__title .accordion__btn {
  padding: 20px 60px 20px 30px;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content {
  padding: 0 20px 15px 30px;
  cursor: pointer;
  /* 見出しの文字と横棒の間隔を開ける */
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content h4 {
  font-size: 1.9rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-top: #ccc 1px solid;
  font-weight: 700;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content h5 {
  font-size: 1.7rem;
  margin-bottom: 1.2rem;
  font-weight: 600;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content li,
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content p {
  display: flex;
  align-items: center; /* 縦位置の調整 */
  font-size: 1.6rem;
  color: #e3f0f6;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content li {
  margin-bottom: 1rem;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content li::before,
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content p::before {
  content: "";
  width: 1.2rem;
  height: 0.5px;
  background: #ccc;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content li::before,
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content p::before {
  margin-right: 10px;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion .accordion__item .accordion__content.is-open {
  display: block;
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion__item.is-active .accordion__title::before {
  transform: rotate(180deg);
}
.overview .overview__wrapper .overview__text__wrapper .work .accordion__item.is-active .accordion__title::after {
  transform: rotate(180deg);
  opacity: 0;
}
.overview .overview__wrapper .overview__text__wrapper .area h2 {
  letter-spacing: 0.1rem;
}
.overview .overview__wrapper .overview__text__wrapper .area p {
  font-size: 2rem;
  font-weight: 800;
  line-height: 3.8rem;
}
.overview .overview__wrapper .overview__text__wrapper .meeting p {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 3.8rem;
}
.overview .overview__wrapper .overview__text__wrapper .meeting span {
  color: #f6e2d2;
}
.overview .overview__wrapper .overview__text__wrapper .access iframe {
  width: 60%;
  margin: 0 auto;
  border-radius: 2%;
}

.footer {
  background-color: #fff;
  padding: 20px;
  z-index: 100;
  padding-bottom: 7rem;
}
.footer img {
  width: 100%;
  height: 2.8rem;
}
.footer ul {
  flex-wrap: wrap;
}
.footer ul li {
  margin-left: 2rem;
}
.footer ul li a {
  color: #000;
  font-size: 1.7rem;
}
.footer ul li a:hover {
  color: #c7c5c5;
}

.notFound {
  background-color: #000;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 1401px) {
  .variety .variety__wrapper {
    height: 550rem;
  }
}
@media (min-width: 1201px) {
  .about {
    height: 310rem;
  }
  .variety .variety__wrapper {
    height: 510rem;
  }
  .flow .flow__wrapper {
    height: 360rem;
  }
  .overview .overview__wrapper {
    height: 380rem;
  }
}
@media (max-width: 1200px) {
  .toppage .title h1 {
    font-size: 4.5rem;
  }
  .about {
    height: 300rem;
  }
  .about .member {
    padding: 20px;
    height: 45rem;
  }
  .about .member img {
    width: 20rem;
    height: 20rem;
    margin-top: 4rem;
  }
  .about .member .member-card {
    padding-right: 4rem;
    padding-left: 3rem;
  }
  .about .member .about-intro {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 5rem;
  }
  .about .review .review__flex div img {
    height: 22rem;
  }
  .variety .variety__wrapper {
    height: 510rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position {
    justify-content: center;
    margin: 0;
    margin-bottom: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy,
.variety .variety__wrapper .variety__wrapper__contents .design {
    padding-top: 4rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field,
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field {
    padding-top: 7rem;
    padding-bottom: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid,
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid {
    width: 40rem; /*横幅*/
    height: 30rem; /*縦幅*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text h4,
.variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text p,
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text h4,
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p {
    width: 100%;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept {
    /*　画像の拡大＋テキスト出現　*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap {
    width: 90%;
    margin: 1.5rem 0;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul {
    font-size: 1.5rem;
  }
  .flow .flow__wrapper {
    height: 400rem;
  }
  .overview .overview__wrapper {
    height: 360rem;
  }
  .overview .overview__wrapper .overview__text__wrapper {
    top: 4%;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position {
    margin-left: 13rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title {
    font-size: 3rem;
    margin-bottom: 2rem;
    /*線の基点とするためrelativeを指定*/
    position: relative;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title:hover::after {
    transform: scale(1, 1); /*X方向にスケール拡大*/
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title::after {
    content: "";
    /*絶対配置で線の位置を決める*/
    position: absolute;
    bottom: -3px;
    left: 0%;
    /*線の形状*/
    width: 15.5%;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title::after {
    background: #fff;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex {
    margin-top: 7rem;
    margin-bottom: 20rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .member-card {
    padding-right: 4rem;
    border-right: #fff 1px solid;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .member-card img {
    width: 15rem;
    height: 15rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text {
    padding-left: 4rem !important;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text li {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
  }
  .overview .overview__wrapper iframe {
    width: 80% !important;
    height: 25rem;
  }
}
@media (max-width: 992px) {
  .toppage .title h1 {
    font-size: 3.5rem;
  }
  .toppage .copyright {
    left: -13%;
  }
  nav .navbar-toggler {
    z-index: 10;
    color: #fff !important;
  }
  nav .nav-list {
    z-index: 10;
    padding-right: 0rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
  nav .nav-list .navbar-nav .nav-item .nav-link {
    font-size: 1.4rem;
    color: #000 !important;
  }
  .works .home .works__wrapper .works__item,
.works .design .works__wrapper .works__item {
    height: 26.1rem;
  }
  .works .home .works__wrapper .works__item img,
.works .design .works__wrapper .works__item img {
    height: 15rem;
    width: 100%;
  }
  .about {
    height: 310rem;
  }
  .about .member {
    padding: 20px;
    height: 49rem;
  }
  .about .member img {
    width: 15rem;
    height: 15rem;
    margin-top: 2rem;
  }
  .about .member .member-card {
    padding-right: 2rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .about .member .about-intro {
    padding: 3rem;
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
  .about .review {
    padding-bottom: 0;
  }
  .about .review span {
    font-weight: lighter;
    font-size: 1.5rem;
  }
  .about .review .review__flex div {
    margin-bottom: 7rem;
  }
  .about .review .review__flex div img {
    height: 18rem;
  }
  .about .review .review__flex div .btn {
    margin-top: 3rem;
  }
  .variety .variety__wrapper {
    height: 530rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents {
    padding-top: 97rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy {
    padding-top: 40px;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text h4 {
    font-size: 2rem;
    margin-bottom: 1.3rem;
    width: 100%;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid-text p {
    font-size: 1.6rem;
    line-height: 2.8rem;
    width: 100%;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid {
    width: 55vh; /*横幅*/
    height: 20vh; /*縦幅*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept img {
    height: 66rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap {
    width: 80%;
    margin: 1.5rem 0;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul {
    font-size: 1.5rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design {
    padding-top: 40px;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p {
    font-size: 2rem;
    margin-bottom: 1.3rem;
    width: 100%;
    margin-top: 1rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid {
    width: 55vh; /*横幅*/
    height: 25vh; /*縦幅*/
  }
  .flow .flow__wrapper {
    height: 410rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .link__position {
    margin-left: 10rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .link__position .link__position__title {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .overview .overview__wrapper .overview__text__wrapper {
    top: 4%;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position {
    margin-left: 10rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex {
    margin-top: 6rem;
    display: block;
    width: 80%;
    margin-bottom: 16rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card {
    padding-right: 0rem;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .member-card img {
    width: 15rem;
    height: 15rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .overview__text {
    padding-left: 2rem !important;
    padding-top: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__card .overview__flex .overview__text li {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
  }
  .overview .scrolldown4 {
    display: none;
  }
  .link .link__wrapper {
    margin-top: 1rem;
  }
  .link .link__wrapper .link__item .overlay {
    opacity: 1 !important;
  }
  .link .link__wrapper .link__item img {
    height: 20rem;
  }
}
@media (max-width: 772px) {
  .header {
    padding-top: 2.6rem;
  }
  .header a {
    margin-top: 0.2rem;
  }
  .header a .navbar-brand {
    width: 6rem;
  }
  .toppage .title h1 {
    font-size: 2.9rem;
  }
  .toppage .copyright {
    left: -15%;
  }
  .works .home .works__wrapper .works__item,
.works .design .works__wrapper .works__item {
    height: 23rem;
  }
  .works .home .works__wrapper .works__item img,
.works .design .works__wrapper .works__item img {
    height: 13rem;
    width: 100%;
  }
  .works .home .works__wrapper .works__item p,
.works .design .works__wrapper .works__item p {
    font-size: 0.8rem;
  }
  .works .home .works__wrapper .works__item {
    height: 25rem;
  }
  .works .home .works__wrapper .works__item h5 {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }
  .works .home .works__wrapper .works__item p {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  .works .design .works__wrapper .works__item {
    height: 22rem;
  }
  .works .design .works__wrapper .works__item h5 {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    padding-bottom: 1.2rem;
  }
  .works .design .works__wrapper .works__item p {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  .works .video .works__wrapper .video-bg {
    width: 49%;
  }
  .works .video .works__wrapper .video-bg:after {
    width: 0%;
  }
  .about {
    height: 440rem;
  }
  .about .member {
    padding: 30px;
    height: 86rem;
    display: block;
  }
  .about .member img {
    width: 15rem;
    height: 15rem;
    margin-top: 2rem;
  }
  .about .member .member-card {
    border: none;
    border-bottom: #7a7a7a 1px solid;
    padding-bottom: 3rem;
  }
  .about .member .about-intro {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .about .review {
    padding-bottom: 0;
  }
  .about .review span {
    font-weight: lighter;
    font-size: 1.5rem;
  }
  .about .review .review__flex div {
    margin-bottom: 7rem;
  }
  .about .review .review__flex div img {
    height: 20rem;
  }
  .about .review .review__flex div .btn {
    margin-top: 3rem;
  }
  .variety .variety__wrapper {
    height: 810vh;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept img {
    height: 72rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design {
    padding-top: 40px;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p {
    margin-bottom: 1.3rem;
    margin-top: 3rem;
  }
  .flow .flow__wrapper {
    height: 440rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents {
    top: 4%;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar {
    width: 100%;
    margin-bottom: 15rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position {
    margin-left: 3rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title {
    font-size: 3rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex {
    margin-top: 6rem;
    width: 100% !important;
    margin-bottom: 15rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .member-card {
    padding-right: 0rem;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text {
    padding-left: 2rem !important;
    padding-top: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text li {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
  }
  .overview .overview__wrapper iframe {
    width: 90% !important;
    height: 22rem;
  }
  .link .link__wrapper .link__item {
    margin-bottom: 0.3rem;
  }
  .link .link__wrapper .link__item img {
    height: 14rem;
  }
}
@media (max-width: 630px) {
  .about section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .header a {
    margin-top: 0.2rem;
  }
  .header a .navbar-brand {
    width: 5rem;
  }
  .toppage .title h1 {
    font-size: 2.45rem;
  }
  .toppage .copyright {
    left: -21%;
  }
  .works .home .works__wrapper,
.works .design .works__wrapper {
    justify-content: flex-start;
  }
  .works .home .works__wrapper .works__item,
.works .design .works__wrapper .works__item {
    height: 23rem;
    width: 49%;
  }
  .works .home .works__wrapper .works__item:after,
.works .design .works__wrapper .works__item:after {
    display: none;
  }
  .works .home .works__wrapper .works__item img,
.works .design .works__wrapper .works__item img {
    height: 13rem;
    width: 100%;
  }
  .works .design .works__wrapper .works__item h5 {
    padding-bottom: 1.4rem;
  }
  .works .video .works__wrapper {
    justify-content: flex-start;
  }
  .works .video .works__wrapper .video-bg {
    width: 49%;
  }
  .works .video .works__wrapper .video-bg:after {
    display: none;
  }
  .about {
    height: 430rem;
  }
  .about .member {
    height: 88rem;
    padding: 30px;
    display: block;
  }
  .about .review {
    margin-bottom: 7rem;
  }
  .variety .variety__wrapper {
    height: 560rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position {
    margin-bottom: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title {
    font-size: 2.3rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field {
    padding-bottom: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid {
    width: 45vh; /*横幅*/
    height: 23vh; /*縦幅*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid-text p {
    font-size: 1.7rem;
    margin-bottom: 1.3rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid {
    width: 45vh; /*横幅*/
    height: 23vh; /*縦幅*/
  }
  .flow .flow__wrapper {
    height: 500rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .link__position {
    margin-left: 0;
    text-align: center;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar {
    width: 100%;
    margin-bottom: 15rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap {
    margin: 6rem 0;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .title {
    margin: 0.8rem;
    font-size: 2.5rem;
    padding-left: 1rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt {
    padding-left: 4rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .txt__weight {
    font-size: 2rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .stepline {
    height: calc(100% + 5.6rem);
  }
  .overview .overview__wrapper {
    height: 320rem;
  }
  .overview .overview__wrapper .overview__text__wrapper {
    top: 3%;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position {
    margin-left: 0rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .link__position .link__position__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .member-card {
    padding-right: 0rem;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text {
    padding-left: 0rem !important;
    padding-top: 4rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .overview__flex .overview__text li {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1.5rem;
    line-height: 2.8rem;
  }
  .overview .overview__wrapper .overview__text__wrapper .work {
    padding-top: 0;
  }
  .overview .overview__wrapper .overview__text__wrapper section {
    padding-bottom: 5rem;
  }
  .overview .overview__wrapper iframe {
    height: 16rem;
    margin-bottom: 8rem;
  }
  .link .link__item img {
    height: 12rem;
  }
  .footer img {
    height: 2rem;
  }
  .footer ul li {
    margin-left: 1rem;
  }
  .footer ul li a {
    font-size: 1.4rem;
  }
}
@media (max-width: 520px) {
  .works .home .works__wrapper,
.works .design .works__wrapper {
    gap: 0.8rem;
  }
  .works .home .works__wrapper .works__item,
.works .design .works__wrapper .works__item {
    height: 21rem;
    width: 48.5%;
  }
  .works .home .works__wrapper .works__item img,
.works .design .works__wrapper .works__item img {
    height: 10rem;
    width: 100%;
  }
  .works .home .works__wrapper .works__item h5 {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }
  .works .home .works__wrapper .works__item p {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
  .works .design .works__wrapper .works__item {
    height: 19rem;
  }
  .works .design .works__wrapper .works__item h5 {
    padding-bottom: 1.4rem;
  }
  .works .video .works__wrapper .video-bg {
    width: 100%;
  }
  .about {
    height: 410rem;
  }
  .about .member {
    padding: 20px;
    height: 91rem;
  }
  .about .member img {
    width: 15rem;
    height: 15rem;
    margin-top: 1rem;
  }
  .about .review .review__flex div {
    margin-bottom: 6rem;
  }
  .about .review .review__flex div img {
    height: 15rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents section {
    padding-bottom: 80px;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy,
.variety .variety__wrapper .variety__wrapper__contents .design {
    padding-top: 0;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid,
.variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid {
    width: 35vh; /*横幅*/
    height: 20vh; /*縦幅*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept img {
    height: 72rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap {
    width: 90%;
  }
  .flow .flow__wrapper {
    height: 525rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents {
    top: 3%;
  }
  .flow .flow__wrapper .flow__wrapper__contents .link__position .link__position__title {
    margin-bottom: 0;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .title {
    margin: 0.8rem;
    font-size: 2.1rem;
    padding-left: 1rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt {
    padding-left: 3.5rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .txt__weight {
    font-size: 2rem;
  }
  .overview .overview__wrapper {
    height: 330rem;
  }
  .link .link__wrapper .link__item img {
    height: 13rem;
  }
  .link .link__wrapper .link__item h4 {
    font-size: 1.5rem;
  }
}
@media (max-width: 441px) {
  .toppage .title h1 {
    font-size: 1.8rem;
  }
  .toppage .copyright {
    left: -28%;
  }
  .works .home .works__wrapper,
.works .design .works__wrapper {
    gap: 0.8rem;
  }
  .works .home .works__wrapper .works__item,
.works .design .works__wrapper .works__item {
    height: 21rem;
    width: 48.5%;
  }
  .works .home .works__wrapper .works__item p,
.works .design .works__wrapper .works__item p {
    font-size: 0.8rem;
  }
  .about {
    height: 390rem;
  }
  .about .member {
    padding: 20px;
    height: 79rem;
  }
  .about .member img {
    width: 15rem;
    height: 15rem;
    margin-top: 1rem;
  }
  .about .member .about-intro {
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 3rem 0.5rem;
  }
  .about .member .about-intro span {
    font-size: 1.6rem;
  }
  .about .review .review__flex div {
    margin-bottom: 6rem;
  }
  .about .review .review__flex div img {
    height: 14rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position {
    margin-bottom: 1.5rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position .link__position__title {
    font-size: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .link__position p {
    font-size: 1.7rem;
    padding: 0 0.8rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy h3 {
    font-size: 2rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field {
    padding-top: 0rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .philosophy .fluid-field .fluid {
    width: 33vh; /*横幅*/
    height: 20vh; /*縦幅*/
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept img {
    height: 74rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap {
    width: 90%;
    margin: 1rem 0;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap ul li {
    margin-bottom: 0.3rem;
    letter-spacing: 0.1rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .concept .zoomInText span.cap .concept__lewis li {
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field {
    padding-top: 0rem;
  }
  .variety .variety__wrapper .variety__wrapper__contents .design .fluid-field .fluid {
    width: 33vh; /*横幅*/
    height: 20vh; /*縦幅*/
  }
  .flow .flow__wrapper {
    height: 550rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar {
    margin-bottom: 13rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .title {
    margin: 0.8rem;
    font-size: 2rem;
    padding-left: 1rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt {
    padding-left: 3.5rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .txt__weight {
    font-size: 1.9rem;
  }
  .overview .overview__wrapper {
    height: 350rem;
  }
  .link .link__item img {
    height: 11rem !important;
  }
  .footer img {
    height: 2rem;
  }
  .footer ul li {
    margin-left: 0.8rem;
  }
  .footer ul li a {
    font-size: 1.4rem;
  }
}
@media (max-width: 420px) {
  .about .member {
    padding: 20px;
    height: 82rem;
  }
  .about .member .about-intro {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .about .member .about-intro span {
    font-size: 1.5rem;
  }
  .variety .variety__wrapper {
    height: 830vh;
  }
  .flow .flow__wrapper {
    height: 570rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents {
    top: 2%;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar {
    margin-bottom: 13rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptitle .title {
    margin: 0.8rem;
    font-size: 2rem;
    padding-left: 0.5rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt {
    padding-left: 2.7rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt {
    font-size: 1.7rem;
    line-height: 3.2rem;
  }
  .flow .flow__wrapper .flow__wrapper__contents .stepbar .stepbarwrap .steptxt .txt .txt__weight {
    font-size: 1.8rem;
  }
  .link .link__item img {
    height: 11rem;
  }
  .footer img {
    height: 2rem;
  }
  .footer ul li {
    margin-left: 0.8rem;
  }
  .footer ul li a {
    font-size: 1.3rem;
  }
}
@media (max-width: 381px) {
  .flow .flow__wrapper {
    height: 600rem;
  }
}