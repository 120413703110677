@mixin overlay($backgroundColor) {
  content: "";
  position: absolute;
  background-color: $backgroundColor;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin middle() {
  display: flex;
  align-items: center;
  justify-content: center;
}
