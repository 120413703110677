/* *************************\
  Basic Modal Styles
\**************************/

.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  display: block;
  /* overflow-y: auto;
  overflow-y: scroll; */
  overflow: scroll;
  /* height: 100vh;
  overflow-y: hidden;
  padding-right: 15px; */
  min-height: 100vh;
}

/* .modal:hover {
  background: rgba(210, 212, 191, 0.9);
} */

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.93);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  /* background-color: #fff; */
  /* padding: 80px; */
  padding: 50px 120px;
  max-width: 900px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
  font-size: 3rem;
  line-height: 1.25;
  color: #fff;
  box-sizing: border-box;
  padding-bottom: 1rem;
  text-align: left;
}

.modal__header .modal__subTitle {
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
}

.modal__close {
  /* background: transparent; */
  background-color: #fff;
  border-radius: 100px;
  border: 0;
  /* padding: 0 0.5rem; */
  width: 4.5rem;
  height: 4.5rem;
  transition: all 0.3s ease;
}

.modal__close:hover {
  background-color: #a9a9a9;
}

.modal__header .modal__close:before {
  content: "\2715";
}

/* .modal__header .modal__close:before:hover {
  color: #fff;
  background-color: #fff;
} */

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  /* color: rgba(0, 0, 0, 0.8); */
  color: #fff;
}

/* modal-work */
.modal__flex {
  display: flex;
  /* justify-content: center; */
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 1rem;
  padding-left: 0 !important;
  margin-bottom: 3.5rem;
  flex-wrap: wrap;
}

.modal__flex a:after {
  content: "";
  display: block;
  width: 32%;
  height: 0;
}

.modal-work .modal__content .modal__flex a {
  width: 32%;
  height: 16rem;
  transform: none;
  overflow: hidden;
}

.modal-work .modal__content .modal__flex a:hover img {
  opacity: 0.7;
  cursor: pointer;
}

.modal-work .modal__content .modal__flex a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 772px) {
  .modal__container {
    padding: 50px 70px;
  }

  .modal__flex {
    gap: 0.8rem;
  }

  .modal-work .modal__content .modal__flex a {
    height: 12.5rem;
  }
}
@media (max-width: 530px) {
  .modal__container {
    padding: 50px 50px;
  }

  .modal__title {
    font-size: 2.5rem;
  }

  .modal__flex {
    gap: 0.6rem;
  }

  .modal-work .modal__content .modal__flex a {
    height: 11rem;
  }
}
@media (max-width: 443px) {
  .modal__container {
    padding: 50px 20px;
  }

  .modal__title {
    font-size: 2.3rem;
  }

  .modal__header .modal__subTitle {
    font-size: 1.4rem;
  }

  .modal__flex {
    gap: 0.4rem;
    padding: 0 !important;
  }

  .modal-work .modal__content .modal__flex a {
    height: 10rem;
  }
}

.modal__photoGallery {
  margin-top: 8rem;
  margin-bottom: 15rem;
}

.modal__photoGallery h4 {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.modal__photoGallery .modal__flex {
  flex-wrap: wrap;
  justify-content: inherit;
}

.modal__photoGallery .modal__flex a {
  width: 32% !important;
}

@media (max-width: 653px) {
  .modal__photoGallery .modal__flex a {
    width: 31% !important;
  }
}

.modal__content ul {
  padding-left: 0;
}

.modal__content ul li {
  font-size: 1.5rem;
  line-height: 3rem;
}

.modal__content ul .modal__sns,
.modal__review {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.modal__content ul .modal__sns span {
  padding-left: 0.9rem;
}

.modal__content ul .modal__review {
  margin-top: 5rem;
}

.modal__content ul .modal__review img {
  width: 1.6rem !important;
  height: auto !important;
  /* margin-right: 0.1rem; */
}

.modal__content ul .modal__review img:nth-child(1) {
  margin-left: 0.8rem;
}

/* about-hiroshi */
.about-hiroshi .modal__content img {
  width: 20rem;
  height: auto;
  padding: 0 !important;
  margin: 1rem 0 3rem 0;
}

.about-hiroshi .modal__content ul li {
  line-height: 3.2rem;
}

.modal__content ul li .modal__close:before {
  content: "\2715";
}

.modal__content ul .modal__text {
  white-space: pre-wrap;
  /* white-space: pre-line; */
  text-align: left;
}

.modal__content ul li a {
  display: inline-block;
  transition: all 0.25s ease;
}

.modal__content ul li a:hover {
  transform: translateY(-2.5px);
}

.modal__content ul li a i {
  margin-left: 0.8rem;
  color: #fff;
  font-size: 1.7rem;
}

.modal__content ul li a i:hover {
  color: #76ecfe;
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
